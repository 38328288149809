import "./App.css";
import "./assect/flexible";
// import faceeLogo from "./images/LOGO.svg";
// import google from "./images/Google.svg";
// import apple from "./images/APPLE.svg";
// import slice1 from "./images/Slice1.png";
// import slice2 from "./images/Slice2.png";
// import slice3 from "./images/Slice3.png";

function App() {
  return (
    <div className="main">
      <div>
        {/* 背景 */}
        <div className="bg">{/* <img src={bg} alt="bg"></img> */}</div>
        {/* 内容区域 */}
        <div className="content">
          {/* 图标 */}
          <img src='https://hybrid.faceeapps.com/vcm/2/20210729/123632/519698310508544/202107291236325.svg' alt="图标" className="icon"></img>
          <h5 className="title-h5">MOKOROOM</h5>
          <h2 className="title-h2">Cartoon your portrait!</h2>
          {/* 下载方式 */}
          <div>
            <a
              href="https://apps.apple.com/us/app/mokoroom-cartoon-photo-editor/id1515241933"
              className="appleTarget"
            >
              <img src='https://hybrid.faceeapps.com/vcm/2/20210729/123734/519961591164928/202107291237349.svg' alt="apple" className="appleImg"></img>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.oceanlook.facee"
              className="google"
            >
              <img src='https://hybrid.faceeapps.com/vcm/2/20210729/123714/519876128026624/202107291237146.svg' alt="googleImg" className="googleImg"></img>
            </a>
          </div>
        </div>
      </div>

      <div className="imgs">
        <img src='https://hybrid.faceeapps.com/vcm/2/20210729/123122/518397988188160/202107291231226.png' alt="slice1"></img>
        <img src='https://hybrid.faceeapps.com/vcm/2/20210729/123423/519158600052736/202107291234238.png' alt="slice2"></img>
        <img src='https://hybrid.faceeapps.com/vcm/2/20210729/123506/519339156451328/202107291235064.png' alt="slice3"></img>
      </div>

      {/* 尾部 */}
      <div className="footer">
        <span className="contact">
          <div>Contact us</div>
          <div className="pop">
            If you have some problem when using Mokoroom, you can tell us in
            Instagram or Email us: <br></br>
            <span>faceevideoapp@gmail.com</span>
          </div>
        </span>
        <a href="http://hybrid.faceeapps.com/web/h5template/ff5cfb8a-bd5b-43c7-ad60-810d21dac91d-language=en/dist/index.html">Privacy Policy</a>
        <a href="http://hybrid.faceeapps.com/web/h5template/16af2d25-0063-4db5-80de-54d9f830a002-language=en/dist/index.html">Terms of use</a>
      </div>
    </div>
  );
}

export default App;
